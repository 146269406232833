<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Fatura</h3>
          <div class="row">
            <div class="col-md-9 mt-4">
              <!-- <label class="col-md-12">Eventos:*</label> -->
              <treeselect
                :multiple="false"
                :options="lista_eventos"
                placeholder="Selecione o evento..."
                v-model="eventos_id"
                @input="preenxerCampos()"
              />
            </div>
          </div>
        </div>
        <div class="p-5" v-show="lista_permissoes_filial.ra_Lucros">
          <h3 class="pl-2 pt-1">Lucros</h3>

          <div class="row">
            <div class="col-md-4" v-for="(item, index) in lucro" :key="index">
              <v-card elevation="10" class="text-center">
                <p style="font-size: 15px">
                  {{ index == 1 ? "pix" : "credito" }}:
                  {{ item | formatMoney }}
                </p>
              </v-card>
            </div>
            <div class="col-md-4">
              <v-card elevation="10" class="text-center">
                <p style="font-size: 15px">
                  {{ "total" }}:
                  {{ (lucro[1] + lucro[2]) | formatMoney }}
                </p>
              </v-card>
            </div>
          </div>

          <div class="d-flex flex-row">
            <hr style="border: 2px dotted black; width: 50%" />
            <span style="font-size: 22px">//</span>
            <hr style="border: 2px dotted black; width: 50%" />
          </div>
        </div>
        <h3 class="pl-2 pt-1">VENDAS ONLINE</h3>


        <div class="card-body table-responsive">
          <div class="row">
            <div
              class="col-md-3"
              v-for="(item, index) in tipo_pagamento"
              :key="index"
            >
              <v-card elevation="10" class="text-center">
                <p style="font-size: 15px">
                  {{ index == 1 ? "pix" : "credito" }}:
                  {{ item.valor | formatMoney }}
                  <br />
                  Quantidade {{ item.count }}
                </p>
              </v-card>
            </div>
            <div class="col-md-3">
              <v-card elevation="10" class="text-center">
                <p style="font-size: 16px">
                  Total {{ total.valor | formatMoney }}
                  <br />
                  Quantidade : {{ total.count }}
                </p>
              </v-card>
            </div>

            <!-- <div class="col-md-4">
              <div class="card text-center">
                <p style="font-size: 20px">
                  Total: {{ (pix + credito) | formatMoney }}
                </p>
              </div>
            </div> -->
          </div>
          <div class="row pt-2">
            <div class="col-md-3" v-for="(item, index) in lote" :key="index">
              <div v-for="(dados, index2) in item" :key="index2">
                <v-card
                  elevation="10"
                  style="font-size: 16px"
                  class="text-center mt-3"
                >
                  {{ index }}
                  <br />
                  Lote: {{ index2 }}
                  <br />
                  {{ dados.valor | formatMoney }}
                  <br />
                  Quantidade : {{ dados.count }}
                  <br />
                  Valor Unitario : {{ dados.val_ing | formatMoney }}
                </v-card>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row mt-5">
            <hr style="border: 2px dotted black; width: 50%" />
            <span style="font-size: 22px">//</span>
            <hr style="border: 2px dotted black; width: 50%" />
          </div>
          <h1 class=" ">filtros</h1>

          <div style="font-size: 12px" class="row">
            <div class="row col-12 col-md-12 col-lg-12 col-12">
              <div class="col-12 col-md-6 col-lg-6">
                <v-text-field
                  style="font-size: 12px"
                  v-model="nome_cliente"
                  label="Cliente"
                  small
                ></v-text-field>
              </div>
              <div class="col-6 col-md-4 col-lg-3">
                <v-text-field
                  style="font-size: 9px"
                  v-model="valor"
                  label="valor"
                ></v-text-field>
              </div>
              <div class="col-6 col-md-4 col-lg-3">
                <v-text-field
                  style="font-size: 9px"
                  v-model="id_transacao"
                  label="transação"
                ></v-text-field>
              </div>
              <div class="col-6 col-md-6 col-lg-6">
                <v-select
                  item-text="label"
                  item-value="value"
                  v-model="tp"
                  :items="tipos_pag"
                ></v-select>
              </div>
              <div class="col-6 col-md-6 col-lg-6">
                <v-select
                  item-text="label"
                  item-value="value"
                  v-model="status"
                  :items="lista_status"
                ></v-select>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-12">
              <v-datetime-picker label="inicio" v-model="datetime1">
              </v-datetime-picker>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-12">
              <v-datetime-picker label="fim" v-model="datetime2">
              </v-datetime-picker>
            </div>
          </div>

          <b-table
            :fields="[
              'nome_sobrenome',
              'subtotal',
              // 'eventos_id',
              'tipo_pagamento',
              // 'desconto',
              'status',
              'data_inclusao',
              'pay_id',
              // 'eventos_id',
            ]"
            :items="lista_faturas"
            :per-page="perPage"
            :current-page="currentPage"
            id="perfil-table"
            class="table table-head-custom table-vertical-center table-head-bg table-borderless text-uppercase mt-3"
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #head(data_inclusao)><span>data inclusão</span></template>
            <template #cell(data_inclusao)="{ item }"
              ><span>{{ item.data_inclusao | formatDate }}</span></template
            >
            <template #cell(status)="{ item }">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': item.status === 'paid',
                  // 'label-light-danger': item.status === '',
                }"
              >
                {{ item.status === "paid" ? "PAGO" : item.status === "waiting_payment"  ?'AGUARDANDO' : 'RECUSADO'}}
              </span></template
            >

            <template #cell(tipo_pagamento)="{ item }">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': item.tipo_pagamento === 1,
                  'label-light-info': item.tipo_pagamento === 2,
                }"
              >
                {{ item.tipo_pagamento === 1 ? "pix" : "credito" }}
              </span></template
            >
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button
                  v-show="lista_permissoes_filial.u_Fatura"
                  @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button
                  v-show="lista_permissoes_filial.lock_Fatura"
                  @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-trash text-danger"></i>
                </button>

                <!-- <button
                  v-show="lista_permissoes_filial.u_Fatura"
                  @click="showModal(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-plus text-success"></i>
                </button> -->
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_faturas.length"
            :per-page="perPage"
            aria-controls="perfil-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
    <!-- <v-dialog v-model="modal_fatura" max-width="900" persistent>
      <template>
        <v-card>
          <v-card-title>Novo Lote</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="form-group row">
              <div class="col-md-3">
                <label class="col-md-12">Titulo:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.tipo"
                  placeholder="Digite o tipo do Fatura"
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Tipo:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.tipo2"
                  placeholder="Digite o tipo do Fatura"
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Lote:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.lote"
                  placeholder="Digite o lote do Fatura"
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Obs:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.obs"
                  placeholder="Digite o lote do Fatura"
                />
              </div>

              <div class="col-md-3">
                <label class="col-md-12">Valor:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.valor"
                  placeholder="Digite o Valor do Fatura"
                  @input="valorFormat"
                  v-mask="variableWithMask"
                />
              </div>
              <div class="col-md-6 pt-1">
                <label class="col-md-12">Quantidade lote:</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.quantidade_lote"
                  placeholder="Quantidade de Fatura do lote"
                />
              </div>
              <div class="row form-group col-md-12 pt-2">
              <div class="col-md-10 pull-left">
                <div class="md-radio-inline text-uppercase">
                  <label>Status:*</label>
                  <div class="space d-flex">
                    <b-form-radio
                      :inline="true"
                      value="2"
                      v-model="form.status"
                    >
                      inativo
                    </b-form-radio>
                    <b-form-radio
                      :inline="true"
                      value="1"
                      v-model="form.status"
                    >
                      ativo
                    </b-form-radio>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="modal_fatura = false">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitNovoLote">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog> -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import dateFormat from "@/core/mixins/dateFormat";
import EventoService from "@/core/services/evento.service";
import moneyFormat from "@/core/mixins/moneyFormat";
import moment from "moment";
export default {
  mixins: [fireAlert, dateFormat, moneyFormat],

  data() {
    return {
      currentPage: 1,
      perPage: 10,
      eventos_id: EventoService.getEventoId(),
      modal_fatura: false,
      fatura: null,

      variableWithMask: "",
      nome_cliente: null,
      id_transacao: null,
      tp: null,
      status:null,

      valor: null,
      datetime1: null,
      datetime2: null,
      tipos_pag: [
      {
          label: "todos",
          value: null,
        },
        {
          label: "pix",
          value: 1,
        },
        {
          label: "credito",
          value: 2,
        },
      ],
      lista_status: [
      {
          label: "todos",
          value: null,
        },
        {
          label: "pago",
          value: 'paid',
        },
        {
          label: "aguardando",
          value: 'waiting_payment',
        },
        {
          label: "recusado",
          value: 'refused',
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Faturas" }]);
  },

  created() {
    this.listar_faturas();
  },
  computed: {
    lista_eventos() {
      return EventoService.getEventos().map((cat) => ({
        id: cat.id,
        label: cat.id + " - " + cat.nome_evento,
      }));
    },
    lista_faturas() {
      let a = this.$store.state.faturas.lista_faturas.fatura.filter((item) => {
        if (!this.eventos_id) return true;
        return (
          String(item["eventos_id"])
            .toLowerCase()
            .indexOf(this.eventos_id + "".toLowerCase()) !== -1
        );
      });
      // console.log(this.tp)
      let b = a.filter((item) => {
        if (!this.tp) return true;
        return (
          String(item["tipo_pagamento"])
            .toLowerCase()
            .indexOf(this.tp + "".toLowerCase()) !== -1
        );
      });

      let c = b.filter((item) => {
        if (!this.nome_cliente) return true;
        return (
          String(item["nome_sobrenome"])
            .toLowerCase()
            .indexOf(this.nome_cliente + "".toLowerCase()) !== -1
        );
      });
      let d = c.filter((item) => {
        if (!this.id_transacao) return true;
        return (
          String(item["pay_id"])
            .toLowerCase()
            .indexOf(this.id_transacao + "".toLowerCase()) !== -1
        );
      });

      let e = d.filter((item) => {
        if (!this.valor) return true;
        return (
          String(item["subtotal"])
            .toLowerCase()
            .indexOf(this.valor + "".toLowerCase()) !== -1
        );
      });
      let f = e.filter((item) => {
        if (!this.status) return true;
        return (
          String(item["status"])
            .toLowerCase()
            .indexOf(this.status + "".toLowerCase()) !== -1
        );
      });
      

      return f.filter((item) => {
        console.log(String(moment(this.datetime1).format("YYYY-MM-DD H:mm")));
        console.log(
          String(moment(item["data_inclusao"]).format("YYYY-MM-DD H:mm"))
        );
        if (!this.datetime1) return true;

        return (
          moment(item["data_inclusao"]).format("YYYY-MM-DD HH:mm") >=
            moment(this.datetime1).format("YYYY-MM-DD HH:mm") &&
          moment(item["data_inclusao"]).format("YYYY-MM-DD HH:mm") <=
            moment(this.datetime2).format("YYYY-MM-DD HH:mm")
        );
      });
      // return   e.filter((item) => {
      //   // console.log(  String(moment(this.datetime2).format('YYYY-MM-DD HH:mm')))
      //   // console.log( String(moment(item["data_inclusao"]).format('YYYY-MM-DD HH:mm')))
      //   if (!this.datetime2) return true;

      //   return (
      //     moment(this.datetime2).format('YYYY-MM-DD HH:mm') <=  moment(this.datetime1).format('YYYY-MM-DD HH:mm'))

      // });
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.faturas.mensagem_alert;
    },

    tipo_pagamento() {
      return this.$store.state.faturas.lista_faturas.tipo_pagamento;
    },
    lote() {
      return this.$store.state.faturas.lista_faturas.lote;
    },
    total() {
      return this.$store.state.faturas.lista_faturas.total;
    },
    lucro() {
      return this.$store.state.faturas.lista_faturas.lucro;
    },
  },
  methods: {
    formatDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    formatDate2(date) {
      return moment(date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm");
    },
    async listar_faturas() {
      await this.$store.dispatch(
        "faturas/listar_faturas",
        EventoService.getEventoId()
      );
      this.$store.dispatch("configEmpresa/atualizar", "");
      console.log(this.pix);

      // this.eventos_id =  this.$route.params.eventos_id
    },
    atualizar(value) {
      this.$router.push({ name: "createFaturas" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },

    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara este PDV no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("faturas/bloquear_fatura", value);
      this.fireAlert({
        ...this.mensagem_alert,
      });
    },
    showModal(item) {
      this.form = {
        obs2: item.obs2,

        desc1: item.desc1,
        desc2: item.desc2,
        desc3: item.desc3,
        cor: item.cor,
        status: item.status,

        eventos_id: item.eventos_id,
        setor_id: item.setor_id,

        tipo: item.tipo,
        tipo2: item.tipo2,

        obs: 1 + parseInt(item.obs.replace(/[^0-9]/g, "")) + "° LOTE",
        quantidade_lote: item.quantidade_lote,
        lote: parseInt(item.lote) + 1,
        valor: null,
      };
      this.fatura = item;
      this.modal_fatura = true;
    },

    async preenxerCampos() {
      EventoService.setEventoId(this.eventos_id);
      await this.$store.dispatch(
        "faturas/listar_faturas",
        EventoService.getEventoId()
      );

      // this.fireAlert({
      //   tipo: "success",
      //   msg: "Selecionado Evento Global",
      // });
    },
    valorFormat() {
      let pa = "";

      for (let i = 0; i < this.form.valor.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }

      this.variableWithMask = "R$" + pa + "#,##";
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>